import React, { useMemo, useEffect, useRef, useState } from "react";

const AxsMapDisplay = ({
  axsMapInfo,
  selectedSections,
  setSelectedSections,
}) => {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const [defaultViewBox, setDefaultViewBox] = useState("0 0 1000 1000");
  const [isShiftSelecting, setIsShiftSelecting] = useState(false);
  const [lastSelectedSection, setLastSelectedSection] = useState(null);

  const handleMouseDown = (event) => {
    if (event.shiftKey) {
      setIsShiftSelecting(true);
      handleSelection(event);
    }
  };

  const aspectRatio = useMemo(() => {
    const [, , width, height] = defaultViewBox.split(",").map(Number);
    return 1 - (height / width) * 100;
  }, [defaultViewBox]);

  const handleMouseUp = () => {
    setIsShiftSelecting(false);
    setLastSelectedSection(null); // Reset the last selected section on mouse up
  };

  const handleMouseMove = (event) => {
    if (isShiftSelecting) {
      handleSelection(event);
    }
  };

  const handleSelection = (event) => {
    const target = event.target.closest("[data-fl]");
    if (target) {
      const sectionName = target.getAttribute("data-fl");

      // Only toggle if the current section is different from the last selected one
      if (sectionName !== lastSelectedSection) {
        if (selectedSections.includes(sectionName)) {
          // Deselect if already selected
          setSelectedSections(
            selectedSections.filter((name) => name !== sectionName)
          );
        } else {
          // Select if not already selected
          setSelectedSections([...selectedSections, sectionName]);
        }
        setLastSelectedSection(sectionName); // Update last selected section
      }
    }
  };

  const handleClick = (event) => {
    if (!event.shiftKey) {
      const target = event.target.closest("[data-fl]");
      if (target) {
        const sectionName = target.getAttribute("data-fl");
        setSelectedSections(
          selectedSections.includes(sectionName)
            ? selectedSections.filter((name) => name !== sectionName)
            : [...selectedSections, sectionName]
        );
      }
    }
  };

  const getFontSize = (item) => {
    return item.fs || 5;
  };

  const isBase64 = (str) => {
    try {
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  const svgContent = useMemo(() => {
    const { svgItems, baseImage, viewBox } = axsMapInfo;
    if (!baseImage) return null;

    let svgElement;
    if (typeof baseImage === "string" && baseImage.startsWith("http")) {
      svgElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "svg"
      );
      if (viewBox) {
        setDefaultViewBox(viewBox);
      }

      const [x, y, ,] = defaultViewBox.split(",");

      svgElement.setAttribute("viewBox", defaultViewBox);
      svgElement.setAttribute("x", x);
      svgElement.setAttribute("y", y);

      const image = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      image.setAttribute("href", baseImage);
      image.setAttribute("width", "100%");
      image.setAttribute("height", "100%");
      image.setAttribute("x", x);
      image.setAttribute("y", y);
      image.setAttribute("preserveAspectRatio", "xMidYMid meet");

      svgElement.appendChild(image);
    } else if (isBase64(baseImage)) {
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(atob(baseImage), "image/svg+xml");
      svgElement = svgDoc.documentElement;

      const svgViewBox = svgElement.getAttribute("viewBox");
      if (svgViewBox) {
        setDefaultViewBox(svgViewBox);
      } else {
        svgElement.setAttribute("viewBox", defaultViewBox);
      }

      svgElement.setAttribute("width", "100%");
      svgElement.setAttribute("height", "100%");
      svgElement.setAttribute("preserveAspectRatio", "xMidYMid meet");
    } else {
      console.error("Unsupported baseImage format");
      return null;
    }

    const interactiveGroup = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "g"
    );
    interactiveGroup.setAttribute("id", "interactive-elements");

    svgItems.forEach((item) => {
      const groupElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "g"
      );
      const pathElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path"
      );
      const sectionName = item.fl || item.id.replace("S_", "");
      const isSelected = selectedSections.includes(sectionName);

      let x, y, ax, ay;

      if (item.c) {
        [x, y] = item.c;
      } else {
        x = 0;
        y = 0;
      }

      if (item.a) {
        [ax, ay] = item.a;
      } else {
        ax = 0;
        ay = 0;
      }

      pathElement.setAttribute("d", item.pt);
      pathElement.setAttribute("d", item.pt);
      pathElement.setAttribute(
        "transform",
        `translate(${x}, ${y}) rotate(${item.fr}, ${ax}, ${ay})`
      );
      pathElement.setAttribute(
        "fill",
        isSelected ? "rgba(2, 108, 223, 0.7)" : "rgba(2, 108, 223, 0.3)"
      );
      pathElement.setAttribute("stroke", isSelected ? "#b81ee3" : "#026cdf");
      pathElement.setAttribute("stroke-width", "2");
      pathElement.setAttribute("id", item.id);
      pathElement.setAttribute("data-fl", sectionName);
      pathElement.style.cursor = "pointer";

      groupElement.appendChild(pathElement);

      if (sectionName && item.a) {
        const textElement = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "text"
        );
        textElement.setAttribute("x", item.a[0]);
        textElement.setAttribute("y", item.a[1]);
        textElement.setAttribute("fill", "black");
        textElement.setAttribute("font-size", getFontSize(item));
        textElement.setAttribute("text-anchor", "middle");
        textElement.setAttribute("dominant-baseline", "middle");
        textElement.setAttribute("pointer-events", "none");
        textElement.setAttribute("user-select", "none");
        textElement.setAttribute(
          "style",
          "-webkit-user-select: none; -moz-user-select: none; -ms-user-select: none;"
        );
        textElement.textContent = sectionName;

        groupElement.appendChild(textElement);
      }

      groupElement.setAttribute("data-fl", sectionName);
      interactiveGroup.appendChild(groupElement);
    });

    svgElement.appendChild(interactiveGroup);

    return svgElement.outerHTML;
  }, [axsMapInfo, selectedSections, defaultViewBox]);

  useEffect(() => {
    if (containerRef.current && svgRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        // Dynamically adjust based on container size if necessary
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [svgContent]);

  useEffect(() => {
    if (svgRef.current && svgContent) {
      svgRef.current.innerHTML = svgContent;
    }
  }, [svgContent]);

  return (
    <div
      ref={containerRef}
      style={{
        paddingTop: `${aspectRatio}%`,
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <svg
        ref={svgRef}
        viewBox={defaultViewBox}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100%",
          width: "100%",
          maxHeight: "100%",
        }}
        preserveAspectRatio="xMidYMid meet"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onClick={handleClick}
        onMouseLeave={handleMouseUp}
      />
    </div>
  );
};

export default AxsMapDisplay;
