import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "../../../ThemeContext";
import { doc, onSnapshot } from "firebase/firestore";
import { auth, db, getCurrentUserEmail } from "../../../firebase";

import IconButton from "@material-ui/core/IconButton";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import NotificationDialog from "../../NotifcationManager/Overlay";

import CornerBox from "./miniDataTable";
import { handleEarlyMonitorToggle } from "../../HomePage/handlers/urlManipulation";
import MuteButton from "./muteEventDrops1";
import Notes from "./notes";
import NotesModal from "../../notes/notes";
import PurchaseButton from "./PurchaseButton";
// import CopyButton from "./copyButton";

function EmailContent({
  event_info,
  quantity,
  map_url,
  groupedTickets,
  eventLink,
  timestamp,
  filterIds,
  handleFilterClick,
  notesDocument,
  userEmail,
  totalSeats,
  stubhub,
  eventId,
  early,
  totalEarly,
  showTable,
  editFilters,
  isLowStock = false,
  vividIds,
  email,
}) {
  groupedTickets.sort((a, b) => a.price - b.price);
  const { darkMode } = useTheme();

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] =
    useState(false);

  const [isChecked, setIsChecked] = useState(early.includes(eventLink));

  const [notesModalOpen, setNotesModalOpen] = useState(false);

  const [stubhubData, setStubhubData] = useState(null);

  // const [presaleData, setPresaleData] = useState(null);

  const [windowWidth, setWindowWidth] = useState(null); // State to store the table width

  const [mapImage, setMapImage] = useState(null);
  const isFetchingRef = useRef(false);

  const changeVF = event_info.faceValueExchange || false;

  // href event link
  let eventLinkHref = eventLink;
  if (eventLink.includes("ticketmaster.com")) {
    eventLinkHref = eventLink.replace(
      "ticketmaster.com",
      "www.ticketmaster.com"
    );
  } else if (eventLink.includes("ticketmaster.ca")) {
    eventLinkHref = eventLink.replace("ticketmaster.ca", "www.ticketmaster.ca");
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (!stubhub) return;

    const stubhubDocRef = doc(db, "stubhubData", String(stubhub));
    const stubhubUnsubscribe = onSnapshot(stubhubDocRef, (doc) => {
      if (doc.exists()) {
        setStubhubData(doc.data());
      }
    });

    return () => {
      stubhubUnsubscribe();
    };
  }, [eventId, userEmail, stubhub]);

  // useEffect(() => {
  //   if (!eventId) return;

  //   const presaleDocRef = doc(db, "presaleCodes", String(eventId));
  //   const presaleUnsubscribe = onSnapshot(presaleDocRef, (doc) => {
  //     if (doc.exists()) {
  //       const presaleData = doc.data();
  //       if (presaleData.offers) {
  //         console.log(presaleData.offers);
  //         setPresaleData(presaleData.offers);
  //       }
  //     }
  //   });

  //   return () => {
  //     presaleUnsubscribe();
  //   };
  // }, [eventId, userEmail]);

  useEffect(() => {
    async function fetchMapImage() {
      try {
        if (isFetchingRef.current || mapImage) return;
        if (
          !map_url ||
          (!eventLink.includes("ticketmaster") &&
            !eventLink.includes("livenation")) ||
          !map_url.includes("mapsapi.tmol.io") ||
          !userEmail
        ) {
          setMapImage(false);
          return;
        }
        isFetchingRef.current = true;

        const response = await fetch(
          "https://api.larryy.com/api/ticketmaster/map-image",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await auth.currentUser.getIdToken()}`,
            },
            body: JSON.stringify({
              url: map_url,
              email: getCurrentUserEmail(),
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const imageResponse = await response.text();

        if (imageResponse === null || imageResponse === "null") {
          throw new Error("response null");
        }

        if (imageResponse.includes("error")) {
          throw new Error("error");
        }

        setMapImage(imageResponse.replace(/"/g, ""));
      } catch (error) {
        setMapImage(false);
        console.error(error);
      }
    }

    fetchMapImage();

    return () => {
      if (mapImage) {
        URL.revokeObjectURL(mapImage);
      }
    };
  }, [eventLink, map_url, userEmail, mapImage]);

  // Generate JSX content for each ticket group
  const rows = groupedTickets.map((group, index) => {
    const seatQuantity = group.seatNumbers?.length || 0;
    let groupPrice = undefined;
    if (group.price) {
      groupPrice = `$${group.price}`;
      if (eventLink.includes(".ca")) {
        groupPrice = `CA $${group.price}`;
      }

      if (email.ticketMasterUK) {
        groupPrice = `£${group.price}`;
      }

      if (email.eu) {
        groupPrice = `€${group.price}`;
      }
    }

    let previousGroupPrice = undefined;
    if (group.previousPrice) {
      previousGroupPrice = `$${group.previousPrice}`;
      if (eventLink.includes(".ca")) {
        previousGroupPrice = `CA $${group.previousPrice}`;
      }

      if (email.ticketMasterUK) {
        groupPrice = `£${group.price}`;
      }

      if (email.eu) {
        groupPrice = `€${group.price}`;
      }
    }

    // length of the groupTickets array if its the last element set value to true
    const isLastElement = index === groupedTickets.length - 1;

    let sectionGetIn = "N/A";
    let sectionLevelGetIn = "N/A";
    const sectionLevels = stubhubData?.total_data;

    if (sectionLevels) {
      for (const category in sectionLevels) {
        if (Object.hasOwnProperty.call(sectionLevels, category)) {
          const sections = sectionLevels[category];

          for (const section of sections) {
            const { t, min } = section;

            if (t && t === group.sectionName) {
              sectionGetIn = min;

              const minArray = sections
                .map((section) => {
                  const minValue = parseFloat(section.min.replace(/[$,]/g, ""));
                  return !isNaN(minValue) ? minValue : null;
                })
                .filter((min) => min !== null && min !== 0);

              if (minArray.length > 0) {
                sectionLevelGetIn = `$${Math.min(...minArray)}`;
              } else {
                sectionLevelGetIn = "$0";
              }

              break;
            }
          }
        }
      }
    }

    const hasGroupPrice = groupedTickets.some((group) => group.price);
    const hasGroupName = groupedTickets.some((group) => group.name);

    let groupName = group.name;
    if (changeVF) {
      groupName = group.name.replace("Verified Resale", "Face Value Exchange");
    }

    return (
      <tr key={index}>
        {group.name ? (
          <td
            style={{
              fontFamily: "'Inter', sans-serif",
              borderRight: "1px solid rgba(0,0,0,0.1)",
              padding: "12px 16px",
              borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
              color: "#fff",
              background: darkMode ? "#4a5568" : "#3182ce",
              textAlign: "center",
              borderBottomLeftRadius: isLastElement ? "8px" : "0px",
              fontSize: "14px",
            }}
          >
            {groupName}
          </td>
        ) : null}
        <td
          style={{
            fontFamily: "'Inter', sans-serif",
            borderLeft: hasGroupName ? "1px solid rgba(0,0,0,0.1)" : "",
            borderRight: "1px solid rgba(0,0,0,0.1)",
            borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
            borderRadius:
              hasGroupName || !isLastElement
                ? "0px 0px 0px 0px"
                : "0px 0px 0px 6px",
            color: "#fff",
            background: darkMode ? "#4a5568" : "#3182ce",
            padding: "12px 16px",
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          {group.sectionName}{" "}
          {group.accessibility?.length > 0 && (
            <span role="img" aria-label="wheelchair">
              ♿
            </span>
          )}
        </td>

        {/* ... rest of the columns with similar styling updates ... */}

        {isLowStock && group.totalAvailableStock && (
          <td
            style={{
              fontFamily: "'Inter', sans-serif",
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
              color: "#fff",
              borderRadius: isLastElement
                ? "0px 0px 6px 0px"
                : "0px 0px 0px 0px",
              background: darkMode ? "#4a5568" : "#3182ce",
              padding: "12px 16px",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {group.totalAvailableStock}
          </td>
        )}

        {group.sectionRow?.length > 0 && (
          <td
            style={{
              fontFamily: "'Inter', sans-serif",
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              borderRight: "1px solid rgba(0,0,0,0.1)",
              borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
              color: "#fff",
              background: darkMode ? "#4a5568" : "#3182ce",
              padding: "12px 16px",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {group.sectionRow}
          </td>
        )}

        {group.seatNumbers?.length > 0 && (
          <td
            style={{
              fontFamily: "'Inter', sans-serif",
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              borderRight: "1px solid rgba(0,0,0,0.1)",
              borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
              color: "#fff",
              background: darkMode ? "#4a5568" : "#3182ce",
              padding: "12px 16px",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {group.seatNumbers.join(", ")} <strong>({seatQuantity})</strong>
          </td>
        )}

        {groupPrice && previousGroupPrice ? (
          <td
            style={{
              fontFamily: "'Inter', sans-serif",
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
              color: "#fff",
              background: darkMode ? "#4a5568" : "#3182ce",
              padding: "12px 16px",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            <strong>
              <span>
                {previousGroupPrice}➜{groupPrice}
              </span>
            </strong>
          </td>
        ) : groupPrice ? (
          <td
            style={{
              fontFamily: "'Inter', sans-serif",
              borderLeft: "1px solid rgba(0,0,0,0.1)",
              padding: "12px 16px",
              color: "#fff",
              background: darkMode ? "#4a5568" : "#3182ce",
              borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
              textAlign: "center",
              borderBottomRightRadius:
                isLastElement && !stubhubData ? "8px" : "0px",
              fontSize: "14px",
            }}
          >
            <strong>{groupPrice}</strong>
          </td>
        ) : (
          hasGroupPrice && (
            <td
              style={{
                fontFamily: "'Inter', sans-serif",
                borderLeft: "1px solid rgba(0,0,0,0.1)",
                padding: "12px 16px",
                color: "#fff",
                background: darkMode ? "#4a5568" : "#3182ce",
                borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
                textAlign: "center",
                borderBottomRightRadius:
                  isLastElement && !stubhubData ? "8px" : "0px",
                fontSize: "14px",
              }}
            >
              <strong>N/A</strong>
            </td>
          )
        )}

        <td
          style={{
            fontFamily: "'Inter', sans-serif",
            borderLeft: "1px solid rgba(0,0,0,0.1)",
            borderRight: "1px solid rgba(0,0,0,0.1)",
            color: "#fff",
            background: darkMode ? "#4a5568" : "#3182ce",
            padding: "12px 16px",
            borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          {group.link.map((link, index) => (
            <a
              key={index}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#fbd38d",
                textDecoration: "underline",
                display: "block",
              }}
            >
              Link {index + 1}
            </a>
          ))}
        </td>

        <td
          style={{
            fontFamily: "'Inter', sans-serif",
            borderLeft: "1px solid rgba(0,0,0,0.1)",
            borderRight: "1px solid rgba(0,0,0,0.1)",
            color: "#fff",
            background: darkMode ? "#4a5568" : "#3182ce",
            padding: "12px 16px",
            borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
            textAlign: "center",
            fontSize: "14px",
          }}
        >
          {group.link.map((link, index) => (
            <PurchaseButton
              key={index}
              group={group}
              index={index}
              eventId={eventId}
              eventName={event_info.name}
            />
          ))}
        </td>

        {stubhubData && !isLowStock && (
          <>
            <td
              style={{
                fontFamily: "'Inter', sans-serif",
                borderLeft: "1px solid rgba(0,0,0,0.1)",
                borderRight: "1px solid rgba(0,0,0,0.1)",
                color: "#fff",
                background: darkMode ? "#553a66" : "#805ad5",
                padding: "12px 16px",
                borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
                textAlign: "center",
                fontSize: "14px",
              }}
            >
              {sectionGetIn}
            </td>
            <td
              style={{
                fontFamily: "'Inter', sans-serif",
                borderLeft: "1px solid rgba(0,0,0,0.1)",
                borderBottom: isLastElement ? "" : "1px solid rgba(0,0,0,0.1)",
                color: "#fff",
                background: darkMode ? "#553a66" : "#805ad5",
                padding: "12px 16px",
                textAlign: "center",
                borderBottomRightRadius: isLastElement ? "8px" : "0px",
                fontSize: "14px",
              }}
            >
              {sectionLevelGetIn}
            </td>
          </>
        )}
      </tr>
    );
  });

  const hasGroupName = groupedTickets.some((group) => group.name);
  const hasGroupPrice = groupedTickets.some((group) => group.price);
  const hasRow = groupedTickets.some((group) => group.sectionRow);
  const hasSeatNumbers = groupedTickets.some((group) => group.seatNumbers);

  let stubhubMin = Infinity;
  if (stubhubData) {
    for (const sectionName in stubhubData.total_data) {
      const section = stubhubData.total_data[sectionName];
      for (const item of section) {
        if (item.min !== undefined) {
          const minPrice = parseFloat(
            item.min.replace("$", "").replace(",", "")
          );
          if (!isNaN(minPrice) && minPrice < stubhubMin && minPrice !== 0) {
            stubhubMin = minPrice;
          }
        }
      }
    }
  }

  const ticketmaster =
    eventLink.includes("ticketmaster") || eventLink.includes("livenation");

  const isMlb = eventLink.includes("mlb.tickets.com");

  const vividUrl = vividIds[eventId] || "";

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "24px",
          gap: "20px",
        }}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <a
            href={eventLinkHref}
            target="_blank"
            rel="noopener noreferrer"
            draggable="true"
            style={{
              textDecoration: "none",
              cursor: "pointer",
              display: "block",
              width: "100%",
            }}
          >
            <div
              style={{
                borderRadius: "12px",
                background: darkMode ? "#1a202c" : "#ffffff",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                padding: "24px",
                margin: "0 0 20px",
                width: "100%",
                transition: "transform 0.2s",
                border: darkMode ? "1px solid #2d3748" : "1px solid #e2e8f0",
              }}
            >
              <h1
                style={{
                  fontFamily: "'Inter', sans-serif",
                  fontSize: "1.8rem",
                  fontWeight: "bold",
                  color: darkMode ? "#f7fafc" : "#2d3748",
                  paddingBottom: "12px",
                  marginTop: 0,
                }}
              >
                {event_info.name}
              </h1>
              <div style={{ display: "flex", gap: "32px" }}>
                <p
                  style={{
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "1rem",
                    color: darkMode ? "#e2e8f0" : "#4a5568",
                    margin: "0",
                    paddingBottom: "8px",
                  }}
                >
                  <strong style={{ color: darkMode ? "#90cdf4" : "#3182ce" }}>
                    Date:
                  </strong>{" "}
                  {event_info.date}
                </p>
                <p
                  style={{
                    fontFamily: "'Inter', sans-serif",
                    fontSize: "1rem",
                    color: darkMode ? "#e2e8f0" : "#4a5568",
                    margin: "0",
                    paddingBottom: "8px",
                  }}
                >
                  <strong style={{ color: darkMode ? "#90cdf4" : "#3182ce" }}>
                    Venue:
                  </strong>{" "}
                  {event_info.venue}
                </p>
              </div>
            </div>
          </a>

          <div
            style={{
              fontFamily: "'Inter', sans-serif",
              display: "flex",
              alignItems: "center",
              userSelect: "none",
              gap: "10px",
              padding: "0 10px",
            }}
          >
            {!editFilters ? (
              <button
                style={{
                  backgroundColor: filterIds.includes(eventId)
                    ? darkMode
                      ? "#4299e1"
                      : "#3182ce"
                    : darkMode
                    ? "#742a2d"
                    : "#e53e3e",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  color: "white",
                  fontFamily: "'Inter', sans-serif",
                  border: "none",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  cursor: "pointer",
                  transition: "all 0.2s",
                }}
                onClick={() =>
                  handleFilterClick([eventId, eventLink, event_info])
                }
              >
                Filters
              </button>
            ) : (
              <button
                onClick={() => {
                  setNotesModalOpen(true);
                }}
                style={{
                  backgroundColor: Object.keys(notesDocument).includes(eventId)
                    ? darkMode
                      ? "#4299e1"
                      : "#3182ce"
                    : darkMode
                    ? "#742a2d"
                    : "#e53e3e",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  color: "white",
                  fontFamily: "'Inter', sans-serif",
                  border: "none",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  cursor: "pointer",
                  transition: "all 0.2s",
                }}
              >
                Notes
              </button>
            )}

            {ticketmaster && !editFilters ? (
              <button
                style={{
                  backgroundColor: isChecked
                    ? darkMode
                      ? "#d69e2e"
                      : "#ecc94b"
                    : darkMode
                    ? "#4a5568"
                    : "#718096",
                  color: isChecked
                    ? darkMode
                      ? "#1a202c"
                      : "#744210"
                    : "white",
                  padding: "8px 16px",
                  borderRadius: "8px",
                  fontFamily: "'Inter', sans-serif",
                  border: "none",
                  cursor:
                    early.length === 0 && totalEarly === 0
                      ? "not-allowed"
                      : "pointer",
                  opacity: early.length === 0 && totalEarly === 0 ? 0.5 : 1,
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  transition: "all 0.2s",
                  fontWeight: isChecked ? "600" : "normal",
                }}
                onClick={(e) => {
                  if (early.length === 0 && totalEarly === 0) {
                    return;
                  }
                  handleEarlyMonitorToggle(
                    eventLink,
                    !isChecked,
                    userEmail,
                    db,
                    totalEarly,
                    early
                  );
                  setIsChecked(!isChecked);
                }}
              >
                Early Monitor
              </button>
            ) : null}

            {stubhub && (
              <a
                href={`https://www.stubhub.com/event/${stubhub}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  background: "#3F1D74",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px 16px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  transition: "all 0.2s",
                }}
              >
                <img
                  src="/stubhubsmall.svg"
                  alt="stubhub-seats-logo"
                  style={{
                    height: 20,
                    width: 19,
                  }}
                />
              </a>
            )}

            {vividUrl && (
              <a
                href={vividUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  background: "black",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px 16px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  transition: "all 0.2s",
                }}
              >
                <img
                  src="/vividsmall.svg"
                  alt="vivid-seats-logo"
                  style={{
                    height: 17,
                    width: 20,
                  }}
                />
              </a>
            )}

            <IconButton
              style={{
                padding: "8px",
                color: darkMode ? "#e2e8f0" : "#4a5568",
                borderRadius: "8px",
                backgroundColor: darkMode ? "#2d3748" : "#edf2f7",
              }}
              onClick={() => {
                setIsNotificationDialogOpen(true);
              }}
            >
              <VolumeUpIcon />
            </IconButton>
          </div>

          <p
            style={{
              fontSize: "1rem",
              color: darkMode ? "#e2e8f0" : "#4a5568",
              padding: "20px 10px 0",
              margin: 0,
              fontFamily: "'Inter', sans-serif",
            }}
          >
            Total Quantity:{" "}
            <strong style={{ color: darkMode ? "#90cdf4" : "#3182ce" }}>
              {quantity}
            </strong>
          </p>
        </div>

        <div style={{ userSelect: "none" }}>
          <div
            style={{
              width: "390px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              borderRadius: "12px",
              overflow: "hidden",
            }}
          >
            {isMlb ? (
              <img
                src="/mlb.png"
                alt="map_image"
                style={{
                  width: "390px",
                  height: "290px",
                  display: "block",
                  borderRadius: "12px",
                }}
              />
            ) : mapImage &&
              typeof mapImage === "string" &&
              mapImage.trim() !== "" ? (
              <img
                src={`data:image/png;base64,${mapImage}`}
                alt="map_image"
                style={{
                  width: "390px",
                  height: "290px",
                  display: "block",
                  borderRadius: "12px",
                }}
                onError={(e) => {
                  console.error("Image failed to load:", e.target.src);
                  e.target.onerror = null;
                  e.target.src =
                    "https://thehcpac.org/wp-content/uploads/2016/11/redticket.png";
                }}
              />
            ) : mapImage !== null ? (
              <img
                src={map_url}
                alt="map_image"
                style={{
                  width: "390px",
                  height: "290px",
                  display: "block",
                  borderRadius: "12px",
                }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://thehcpac.org/wp-content/uploads/2016/11/redticket.png";
                }}
              />
            ) : (
              <div
                style={{
                  width: "390px",
                  height: "290px",
                  display: "block",
                  background: darkMode ? "#2d3748" : "#edf2f7",
                  borderRadius: "12px",
                }}
              ></div>
            )}
          </div>
          <CornerBox
            totalSeats={totalSeats}
            priceRange={event_info.priceRange}
            stubhubData={stubhubData}
            stubhubMin={stubhubMin}
            darkMode={darkMode}
            axsData={eventLink.includes("axs.com")}
            seatGeekData={eventLink.includes("seatgeek.com")}
            mlbData={eventLink.includes("mlb.tickets.com")}
            stubhubDropData={eventLink.includes("stubhub.com")}
          />
        </div>
        <NotificationDialog
          open={isNotificationDialogOpen}
          onClose={() => setIsNotificationDialogOpen(false)}
          eventId={eventId}
        />
      </div>

      <a
        href={eventLinkHref}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: darkMode ? "#90cdf4" : "#3182ce",
          textDecoration: "none",
          fontFamily: "'Inter', sans-serif",
          marginLeft: "10px",
          fontSize: "1rem",
          padding: "8px 16px",
          display: "inline-block",
          borderRadius: "6px",
          background: darkMode
            ? "rgba(144, 205, 244, 0.1)"
            : "rgba(49, 130, 206, 0.1)",
          transition: "all 0.2s",
        }}
      >
        {eventLink.includes("tix.axs.com") ? "AXS Link" : eventLink}
      </a>

      <div
        style={{
          display: "flex",
          gap: "20px",
          marginTop: "30px",
          padding: "0 10px",
        }}
      >
        <div style={{ position: "relative", flex: 1 }}>
          <div
            style={{
              position: "relative",
              width: "fit-content",
            }}
          >
            <table
              style={{
                borderCollapse: "separate",
                borderSpacing: 0,
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                width: "100%",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <thead>
                <tr>
                  {hasGroupName && (
                    <th
                      style={{
                        background: darkMode ? "#2d3748" : "#4a5568",
                        textAlign: "center",
                        padding: "12px 16px",
                        borderTopLeftRadius: "8px",
                        fontFamily: "'Inter', sans-serif",
                        color: "white",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      Ticket Type
                    </th>
                  )}
                  <th
                    style={{
                      background: darkMode ? "#2d3748" : "#4a5568",
                      textAlign: "center",
                      borderTopLeftRadius: hasGroupName ? "0px" : "8px",
                      padding: "12px 16px",
                      fontFamily: "'Inter', sans-serif",
                      color: "white",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Section
                  </th>

                  {/* ... other table headers with similar styling ... */}

                  {isLowStock && (
                    <th
                      style={{
                        background: darkMode ? "#2d3748" : "#4a5568",
                        textAlign: "center",
                        padding: "12px 16px",
                        fontFamily: "'Inter', sans-serif",
                        color: "white",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      Low Stock
                    </th>
                  )}

                  {hasRow && (
                    <th
                      style={{
                        background: darkMode ? "#2d3748" : "#4a5568",
                        textAlign: "center",
                        padding: "12px 16px",
                        fontFamily: "'Inter', sans-serif",
                        color: "white",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      Row
                    </th>
                  )}

                  {hasSeatNumbers && (
                    <th
                      style={{
                        background: darkMode ? "#2d3748" : "#4a5568",
                        textAlign: "center",
                        padding: "12px 16px",
                        fontFamily: "'Inter', sans-serif",
                        color: "white",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      Seats (Qty)
                    </th>
                  )}

                  {hasGroupPrice && (
                    <th
                      style={{
                        background: darkMode ? "#2d3748" : "#4a5568",
                        textAlign: "center",
                        borderTopRightRadius: !stubhubData ? "8px" : "0",
                        padding: "12px 16px",
                        fontFamily: "'Inter', sans-serif",
                        color: "white",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        fontSize: "14px",
                      }}
                    >
                      Price
                    </th>
                  )}

                  <th
                    style={{
                      background: darkMode ? "#2d3748" : "#4a5568",
                      textAlign: "center",
                      padding: "12px 16px",
                      fontFamily: "'Inter', sans-serif",
                      color: "white",
                      whiteSpace: "nowrap",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Links
                  </th>

                  {stubhubData && !isLowStock && (
                    <>
                      <th
                        style={{
                          background: darkMode ? "#44337a" : "#6b46c1",
                          textAlign: "center",
                          padding: "12px 16px",
                          fontFamily: "'Inter', sans-serif",
                          color: "white",
                          whiteSpace: "nowrap",
                          fontWeight: 600,
                          fontSize: "14px",
                        }}
                      >
                        Sec Price
                      </th>
                      <th
                        style={{
                          background: darkMode ? "#44337a" : "#6b46c1",
                          textAlign: "center",
                          padding: "12px 16px",
                          fontFamily: "'Inter', sans-serif",
                          color: "white",
                          borderTopRightRadius: "8px",
                          whiteSpace: "nowrap",
                          fontWeight: 600,
                          fontSize: "14px",
                        }}
                      >
                        Cheapest In Lvl
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>

        <Notes
          stubhubData={stubhubData}
          note={notesDocument[eventId] || ""}
          darkMode={darkMode}
        />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "24px",
          padding: "16px",
          borderRadius: "8px",
          background: darkMode ? "#1a202c" : "#f7fafc",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          border: darkMode ? "1px solid #2d3748" : "1px solid #e2e8f0",
        }}
      >
        <p
          style={{
            fontFamily: "'Inter', sans-serif",
            color: darkMode ? "#e2e8f0" : "#4a5568",
            margin: 0,
            flex: 1,
          }}
        >
          <strong
            style={{
              fontFamily: "'Inter', sans-serif",
              color: darkMode ? "#90cdf4" : "#3182ce",
            }}
          >
            Release Time:
          </strong>{" "}
          {new Date(timestamp).toLocaleString()}
        </p>

        {!editFilters && !showTable && (
          <MuteButton
            eventId={eventId}
            user={getCurrentUserEmail()}
            eventUrl={eventLink}
            totalEarly={totalEarly}
            early={early}
            mainUser={userEmail}
          />
        )}
        <NotesModal
          eventId={eventId}
          notesDocument={notesDocument}
          userEmail={userEmail}
          open={notesModalOpen}
          handleClose={() => setNotesModalOpen(false)}
        />
      </div>
    </>
  );
}

export default EmailContent;
