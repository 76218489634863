import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { useEventContext } from "../../HomePage/UserDataContext";

function PurchaseButton({ group, index, eventId, eventName }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState("");
  // const { tmAccounts } = useEventContext();

  // const profiles = [...new Set(tmAccounts.map((account) => account.profile))];

  // useEffect(() => {
  //   const lastUsedProfile = localStorage.getItem("lastUsedProfile");

  //   if (!selectedProfile) {
  //     if (lastUsedProfile && profiles.includes(lastUsedProfile)) {
  //       setSelectedProfile(lastUsedProfile);
  //     } else if (profiles.length > 0) {
  //       setSelectedProfile(profiles[0]);
  //     }
  //   }
  // }, [profiles, selectedProfile]);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmPurchase = async () => {
    const UrlInfo = group.link[index].split("/");
    console.log(UrlInfo);

    // get the last element of the array
    let requestId = UrlInfo[UrlInfo.length - 1];
    requestId = requestId.split("?")[0];
    console.log(requestId);

    // sort the seat numbers in ascending order
    const sortedSeatNumbers = group.seatNumbers.sort((a, b) => a - b);

    // Save selected profile to localStorage
    // localStorage.setItem("lastUsedProfile", selectedProfile);

    // fetch
    // const response = await fetch(
    //   "https://api.larryy.com/api/ticketmaster/checkout",
    //   {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     method: "POST",
    //     body: JSON.stringify({
    //       profile: selectedProfile,
    //       requestId: requestId,
    //       eventId: eventId,
    //       ticket: {
    //         endSeat: sortedSeatNumbers[sortedSeatNumbers.length - 1],
    //         row: group.sectionRow,
    //         section: group.sectionName,
    //         startSeat: sortedSeatNumbers[0],
    //         id: group.ticketId,
    //         quantity: sortedSeatNumbers.length,
    //         price: group.price,
    //       },
    //     }),
    //   }
    // );
    const response = await fetch(
      "https://bob.larryy.com/api/events?guest=1",
      {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          username: "",
          avatar_url: "",
          tts: false,
          embeds: [
            {
              color: 620905,
              title: "Successfully carted!",
              description: "",
              footer: {
                text: "",
                icon_url: "",
              },
              fields: [
                {
                  name: "Store",
                  value: "US",
                  inline: true,
                },
                {
                  name: "Event ID",
                  value: eventId,
                  inline: true,
                },
                {
                  name: "Event name",
                  value: eventName,
                  inline: true,
                },
                {
                  name: "Account",
                  value: "",
                  inline: true,
                },
                {
                  name: "Password",
                  value: "",
                  inline: true,
                },
                {
                  name: "Section",
                  value: group.sectionName,
                  inline: true,
                },
                {
                  name: "Row",
                  value: group.sectionRow,
                  inline: true,
                },
                {
                  name: "Price",
                  value: group.price,
                  inline: true,
                },
                {
                  name: "Amount",
                  value: group.quantity,
                  inline: true,
                },
                {
                  name: "Full checkout",
                  value: group.link[index],
                  inline: false,
                },
              ],
            },
          ],
          components: [],
        }),
      }
    );

    const data = await response.json();
    console.log(data);

    closeDialog();
  };

  return (
    <>
      <button onClick={openDialog}>Buy</button>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Confirm Purchase"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to buy this ticket?
          </DialogContentText>
          {/* <select
            value={selectedProfile}
            onChange={(e) => setSelectedProfile(e.target.value)}
            style={{ marginTop: "20px", width: "100%", padding: "8px" }}
          >
            {profiles.map((profile) => (
              <option key={profile} value={profile}>
                Profile - {profile}
              </option>
            ))}
          </select> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmPurchase} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PurchaseButton;
