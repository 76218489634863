const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  handleSubmit,
}) => (
  <form onSubmit={handleSubmit} className="login-form">
    <input
      type="email"
      placeholder="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
      autoFocus
    />
    <input
      type="password"
      placeholder="Password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      required
    />
    <button type="submit">Login</button>
  </form>
);

export { LoginForm };
