import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  makeStyles,
  Popover,
} from "@material-ui/core";
import { useTheme } from "../ThemeContext";
import { useState } from "react";
import { useEventContext } from "./HomePage/UserDataContext";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PaymentIcon from "@mui/icons-material/Payment";

import "../css/SideBar.css";
import NotificationDialog from "./NotifcationManager/Overlay";
import MainAccountOverlay from "./AccountManager/MainAcccountOverlay";
import AccountManager from "./tmAccountManager/tmAccount";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    background: "#1a1a1a",
    padding: "4px 16px",
    minHeight: "56px !important",
  },
  logo: {
    height: "32px",
    width: "32px",
    marginRight: theme.spacing(1),
    borderRadius: "6px",
  },
  title: {
    height: "36px",
    width: "auto",
    marginLeft: theme.spacing(1),
    marginTop: "10px",
    display: "inline-block",
  },
  rightSection: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  buttonText: {
    marginLeft: 8,
    fontSize: 13,
    color: "white",
  },
  twentyFiveDayText: {
    backgroundColor: "#2d2d2d",
    color: "#e0e0e0",
    padding: "4px 12px",
    borderRadius: "6px",
    border: "1px solid #404040",
    fontSize: "13px",
  },
  popover: {
    borderRadius: "15px",
  },
  menu: {
    position: "absolute",
    top: "500px",
    right: "0px",
    zIndex: 1,
  },
  menuContent: {
    backgroundColor: "#1a1a1a",
    padding: "8px 12px",
    color: "#e0e0e0",
    borderRadius: "6px",
  },
  menuButton: {
    backgroundColor: "#2d2d2d",
    margin: "4px 0",
    display: "flex",
    alignItems: "center",
    color: "#e0e0e0",
    padding: "6px 12px",
    borderRadius: "6px",
    border: "1px solid #404040",
    cursor: "pointer",
    width: "100%",
    justifyContent: "flex-start",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#404040",
      borderColor: "#606060",
    },
  },
  menuButtonText: {
    fontSize: "14px",
    marginLeft: "8px",
    fontFamily: "'Inter', sans-serif",
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: "transparent",
    border: "1px solid #404040",
    borderRadius: "6px",
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: "#2d2d2d",
      borderColor: "#606060",
    },
  },
  link: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "inherit",
  },
  startStopButton: {
    backgroundColor: (props) => (props.startAndStop ? "#ff4444" : "#2ecc71"),
    color: "white",
    padding: "8px 20px",
    borderRadius: "12px",
    border: "2px solid",
    borderColor: (props) => (props.startAndStop ? "#ff2222" : "#27ae60"),
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "600",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    transition: "all 0.2s ease",
    boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
    "&:hover": {
      backgroundColor: (props) => (props.startAndStop ? "#ff2222" : "#27ae60"),
      transform: "translateY(-2px)",
      boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
    },
    "&:active": {
      transform: "translateY(1px)",
    },
  },
}));

const AppHeader = ({ showTicketDrops }) => {
  const { darkMode, toggleDarkMode, user } = useTheme();
  const classes = useStyles({ startAndStop: false });
  const userContext = useEventContext();
  const { twentyFiveDay, planType, mainUser, allowedEmails, startAndStop } =
    userContext;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isNotificationOpen, setisNotificationOpen] = useState(false);
  const [isManagerOpen, setisManagerOpen] = useState(false);

  const openPopup = (event) => {
    setAnchorEl(event.currentTarget);
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    setIsPopupOpen(false);
  };

  const handleStartStop = () => {
    const docRef = doc(db, "users", mainUser);

    updateDoc(docRef, {
      startAndStop: !startAndStop,
    });
  };

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar className={classes.toolbar} style={{ minHeight: "56px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            userSelect: "none",
            minWidth: 0,
            overflow: "hidden",
          }}
        >
          <img
            alt="Logo"
            src="/larry.jpg"
            className={classes.logo}
            style={{ flexShrink: 0 }}
          />
          <Typography
            style={{
              marginLeft: "10px",
              color: "white",
              userSelect: "text",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "200px",
            }}
          >
            {user.email}
          </Typography>
          <IconButton
            onClick={openPopup}
            className={classes.iconButton}
            style={{ marginLeft: "20px", flexShrink: 0 }}
            disableRipple
          >
            <SettingsApplicationsIcon
              style={{ color: "#e0e0e0", fontSize: "26px" }}
            />
          </IconButton>
          <Popover
            open={isPopupOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            classes={{
              paper: classes.popover,
            }}
            PaperProps={{
              style: {
                backgroundColor: "#222222",
                color: "white",
              },
            }}
          >
            <div className={classes.menuContent}>
              <button
                className={classes.menuButton}
                onClick={() => {
                  setisNotificationOpen(true);
                  setIsPopupOpen(false);
                }}
              >
                <NotificationsActiveIcon style={{ color: "#e0e0e0" }} />
                <span className={classes.menuButtonText}>Notifications</span>
              </button>
              {user.email === userContext.mainUser && (
                <button
                  className={classes.menuButton}
                  onClick={() => {
                    setisManagerOpen(true);
                    setIsPopupOpen(false);
                  }}
                >
                  <ManageAccountsIcon style={{ color: "#e0e0e0" }} />
                  <span className={classes.menuButtonText}>
                    Account Manager
                  </span>
                </button>
              )}
              {planType === "whop" && (
                <button
                  className={classes.menuButton}
                  onClick={() => {
                    window.open("https://whop.com/hub/memberships/", "_blank");
                  }}
                >
                  <PaymentIcon style={{ color: "#e0e0e0" }} />
                  <span className={classes.menuButtonText}>
                    Manage Whop Plan
                  </span>
                </button>
              )}
            </div>
          </Popover>
          <NotificationDialog
            open={isNotificationOpen}
            onClose={() => {
              setisNotificationOpen(false);
            }}
          />
          <MainAccountOverlay
            open={isManagerOpen}
            handleClose={() => {
              setisManagerOpen(false);
            }}
          />
          <IconButton
            onClick={toggleDarkMode}
            className={classes.iconButton}
            disableRipple
          >
            {darkMode ? (
              <LightModeIcon style={{ color: "#e0e0e0", fontSize: "24px" }} />
            ) : (
              <DarkModeIcon style={{ color: "#e0e0e0", fontSize: "24px" }} />
            )}
          </IconButton>
          {allowedEmails.includes(mainUser) ? (
            <IconButton
              component={Link}
              to="/sales"
              className={classes.iconButton}
              style={{ padding: 0, flexShrink: 1, minWidth: 0 }}
              disableRipple
            >
              <button
                className={classes.menuButton}
                style={{
                  backgroundColor: "#0000FF",
                  fontSize: "18px",
                  borderRadius: "16px",
                  fontFamily: "'Inter', sans-serif",
                  color: "white",
                  height: "30px",
                  marginLeft: "20px",
                  whiteSpace: "nowrap",
                  padding: "0 12px",
                  minWidth: 0,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "250px",
                }}
              >
                Onsale / Presale (BETA)
              </button>
            </IconButton>
          ) : null}
          {/* <AccountManager /> */}
        </div>
        <Box className={classes.rightSection} style={{ flexShrink: 0 }}>
          <button onClick={handleStartStop} className={classes.startStopButton}>
            {startAndStop ? (
              <>
                <span style={{ fontSize: "20px" }}>⏹️</span>
                Press to Stop Monitor
              </>
            ) : (
              <>
                <span style={{ fontSize: "20px" }}>▶️</span>
                Press to Start Monitor
              </>
            )}
          </button>
          {twentyFiveDay && (
            <Typography variant="body2" className={classes.twentyFiveDayText}>
              $25 Day Account
            </Typography>
          )}
          <IconButton
            component={Link}
            to="/"
            className={classes.iconButton}
            disableRipple
          >
            <HomeWorkIcon style={{ color: "#e0e0e0", fontSize: "26px" }} />
          </IconButton>
          {!showTicketDrops && (
            <IconButton
              component={Link}
              to="/ticket-drops"
              className={classes.iconButton}
              disableRipple
            >
              <ConfirmationNumberIcon
                style={{ color: "#e0e0e0", fontSize: "26px" }}
              />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
