import React, { useState } from "react";

function SvgPaths({ onPathClick, clickedPaths, data }) {
  const [isMouseDown, setIsMouseDown] = useState(false);

  const handleMouseDown = (event, path) => {
    if (event.shiftKey) {
      setIsMouseDown(true);
    }
    handlePathClick(path); // Register the clicked path immediately, regardless of Shift key
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handlePathClick = (path) => {
    onPathClick(path);
  };

  const handleMouseEnter = (event, path) => {
    if (isMouseDown && event.shiftKey) {
      handlePathClick(path);
    }
  };

  return (
    <svg
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Stops dragging if the mouse leaves the SVG area
    >
      {data.map((path, index) => (
        <path
          key={index}
          data-component="svg__section"
          data-section-id={path.id}
          data-section-name={path.name}
          className={`clickable-path block is-available is-filtered ${
            clickedPaths.includes(path.name) ? "maximum" : "minimum"
          }`}
          onMouseDown={(event) => handleMouseDown(event, path)}
          onMouseEnter={(event) => handleMouseEnter(event, path)}
          d={path.shapes}
        ></path>
      ))}
    </svg>
  );
}

export default SvgPaths;
