import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBk1oonZiDctpsz92jj87x65I9hY261K8Y",
  authDomain: "larry-b6cf9.firebaseapp.com",
  projectId: "larry-b6cf9",
  storageBucket: "larry-b6cf9.firebasestorage.app",
  messagingSenderId: "954941072309",
  appId: "1:954941072309:web:b67a4998c7bb154328effc",
  measurementId: "G-5LJFKRLKQG",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 1;

getAnalytics(app);

export const getCurrentUserEmail = () => {
  return auth.currentUser.email;
};

export { auth, db, remoteConfig, storage };
