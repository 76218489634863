import { useState, useEffect } from "react";
import {
  arrayUnion,
  doc,
  setDoc,
  onSnapshot,
  updateDoc,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import XIcon from "../icons/XIcon";
import {
  Dialog,
  DialogContent,
  FormControl,
  TextareaAutosize,
} from "@mui/material";

export default function EventManager({ email, eventId }) {
  const [open, setOpen] = useState(false);
  const [codes, setCodes] = useState([]);
  const [didIds, setDidIds] = useState([]);
  const [newCode, setNewCode] = useState("");
  const [newDidIds, setNewDidIds] = useState("");

  useEffect(() => {
    if (!email || !eventId) return;

    const docRef = doc(db, "Codes", eventId.toString());

    const unsub = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setCodes(data.codes ?? []);
          setDidIds(data.didIds ?? []);
        } else {
          setCodes([]);
          setDidIds([]);
        }
      },
      (error) => {
        console.error("Error fetching data:", error);
        setCodes([]);
        setDidIds([]);
      }
    );

    return () => unsub();
  }, [email, eventId]);

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => {
    setOpen(false);
    setNewCode("");
    setNewDidIds("");
  };

  const handleAddCode = async () => {
    if (!newCode.trim() || !eventId) return;
    const codesToAdd = newCode
      .split(/[\n\r]+/)
      .map((code) => code.trim())
      .filter((code) => code !== "");
    const docRef = doc(db, "Codes", eventId.toString());
    try {
      await setDoc(
        docRef,
        {
          codes: arrayUnion(...codesToAdd),
        },
        { merge: true }
      );
      setNewCode("");
    } catch (error) {
      console.error("Error adding codes:", error);
    }
  };

  const handleAddDidIds = async () => {
    if (!newDidIds.trim() || !eventId) return;

    const didIdsToAdd = newDidIds
      .split("\n")
      .map((didId) => didId.trim())
      .filter((didId) => didId);
    const docRef = doc(db, "Codes", eventId.toString());
    try {
      await setDoc(
        docRef,
        {
          didIds: arrayUnion(...didIdsToAdd),
        },
        { merge: true }
      );
      setNewDidIds("");
    } catch (error) {
      console.error("Error adding DID IDs:", error);
    }
  };

  const handleRemoveCode = async (codeToRemove) => {
    if (!eventId) return;

    const docRef = doc(db, "Codes", eventId);
    try {
      await updateDoc(docRef, {
        codes: arrayRemove(codeToRemove),
      });
    } catch (error) {
      console.error("Error removing code:", error);
    }
  };

  const handleRemoveDidIds = async (didIdToRemove) => {
    if (!eventId) return;

    const docRef = doc(db, "Codes", eventId);
    try {
      await updateDoc(docRef, {
        didIds: arrayRemove(didIdToRemove),
      });
    } catch (error) {
      console.error("Error removing queue token:", error);
    }
  };

  return (
    <div>
      <button
        onClick={handleOpenModal}
        className="bg-[#0000FF] rounded-[9px] cursor-pointer py-1 px-5 !text-white text-sm uppercase w-36"
      >
        Event Manager
      </button>

      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullWidth={true}
        maxWidth="xl"
      >
        <div className="bg-[#DBDBDB] dark:bg-[#222222] text-white rounded-xl pb-1">
          <div className="flex items-center justify-between !bg-[#C5C5C5] dark:!bg-[#2c2c2c] rounded-t-xl py-2 px-3">
            <div className="flex flex-grow text-center">
              <p className="text-[#3C3C3C] dark:text-white m-0 flex-1 text-[14px] font-bold">
                Codes & DID ID Manager
              </p>
            </div>
            <button
              onClick={handleCloseModal}
              className="bg-black/30 dark:bg-[#595959] rounded-full p-1"
            >
              <XIcon className="w-3 h-3" />
            </button>
          </div>
          <DialogContent>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <h3 className="text-black dark:text-white text-lg mb-2">
                  Codes
                </h3>
                <div className="flex gap-3 mb-4">
                  <TextareaAutosize
                    placeholder="Enter codes, one per line"
                    value={newCode}
                    onChange={(e) => setNewCode(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleAddCode();
                      }
                    }}
                    className="flex-1 min-w-[300px] p-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-black dark:text-white focus:ring-2 focus:ring-[#0000FF] focus:border-transparent transition-all"
                    rows={4}
                  />
                  <button
                    onClick={handleAddCode}
                    className="h-fit bg-[#0000FF] hover:bg-[#7a0005] transition-colors rounded-[9px] cursor-pointer py-2 px-6 !text-white text-sm uppercase whitespace-nowrap shadow-sm"
                  >
                    Add Code
                  </button>
                </div>
                <div className="max-h-[400px] overflow-y-auto">
                  {codes.map((code, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-2 border-b border-gray-300 dark:border-gray-700"
                    >
                      <span className="text-black dark:text-white">{code}</span>
                      <button
                        onClick={() => handleRemoveCode(code)}
                        className="bg-red-600 rounded-[9px] cursor-pointer py-1 px-3 !text-white text-sm"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h3 className="text-black dark:text-white text-lg mb-2">
                  DID ID's
                </h3>
                <div className="flex gap-2 mb-4">
                  <FormControl fullWidth>
                    <TextareaAutosize
                      placeholder="Enter URL with DID ID"
                      value={newDidIds}
                      onChange={(e) => setNewDidIds(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleAddDidIds();
                        }
                      }}
                      className="flex-1 min-w-[300px] p-3 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-black dark:text-white focus:ring-2 focus:ring-[#0000FF] focus:border-transparent transition-all"
                      rows={1}
                    />
                  </FormControl>
                  <button
                    onClick={handleAddDidIds}
                    className="bg-[#0000FF] rounded-[9px] cursor-pointer py-1 px-5 !text-white text-sm uppercase whitespace-nowrap"
                  >
                    Add DID ID
                  </button>
                </div>
                <div className="max-h-[400px] overflow-y-auto">
                  {didIds.map((didId, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between p-2 border-b border-gray-300 dark:border-gray-700"
                    >
                      <span className="text-black dark:text-white text-sm">
                        {didId}
                      </span>
                      <button
                        onClick={() => handleRemoveDidIds(didId)}
                        className="bg-red-600 rounded-[9px] cursor-pointer py-1 px-3 !text-white text-sm"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
