import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

export const handleWhopLogin = () => {
  let clientId = "TWROEf2oM5NVAzs4nfTSN99GqD819AyEMjSLNrLjvP8";
  let redirectUri = encodeURIComponent("https://larryy.com/callback");

  let authUrl = `https://whop.com/oauth?client_id=${clientId}&redirect_uri=${redirectUri}`;

  window.location.href = authUrl;
};

export const fetchClientDetails = async () => {
  const ipAddress = await fetch(
    "https://api.larryy.com/api-internal/extra/get-ip"
  )
    .then((res) => res.json())
    .then((data) => data.ip);
  const userAgent = navigator.userAgent;
  return { ipAddress, userAgent };
};

export const generateSessionId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const handleSession = async (userCredential, db) => {
  const { ipAddress, userAgent } = await fetchClientDetails();
  const sessionId = generateSessionId();

  document.cookie = `sessionid=${sessionId};`;

  const sessionDocRef = doc(db, "sessions", userCredential.user.email);
  const sessionDocSnapshot = await getDoc(sessionDocRef);

  if (sessionDocSnapshot.exists()) {
    const sessionInfo = sessionDocSnapshot.data();
    const { totalSessions, sessions: SessionInfo } = sessionInfo;

    if (totalSessions === SessionInfo.length) {
      SessionInfo.shift();
    }

    SessionInfo.push({ ipAddress, userAgent, sessionId });
    await setDoc(sessionDocRef, { sessions: SessionInfo }, { merge: true });
  } else {
    await setDoc(sessionDocRef, {
      totalSessions: 1,
      sessions: [{ ipAddress, userAgent, sessionId }],
    });
  }

  const userDocRef = doc(db, "Logins", userCredential.user.email);
  const userDocSnapshot = await getDoc(userDocRef);

  const loginData = {
    ipAddress,
    userAgent,
    loginAt: new Date().toISOString(),
  };

  if (userDocSnapshot.exists()) {
    await updateDoc(userDocRef, { logins: arrayUnion(loginData) });
  } else {
    await setDoc(userDocRef, { logins: [loginData] });
  }
};
