import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "../../../ThemeContext";
import DropLogo from "./dropLogo";

const useStyles = makeStyles((theme) => ({
  emailCard: {
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    margin: "8px 0",
    borderRadius: "12px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    cursor: "pointer",
    transition: "all 0.2s ease",
    position: "relative",
  },
  emailCardLight: {
    backgroundColor: "#ffffff",
    "&:hover": {
      boxShadow: "0 4px 12px rgba(0,0,0,0.15)",
      transform: "translateY(-2px)",
    },
  },
  emailCardDark: {
    backgroundColor: "#1E1E1E",
    "&:hover": {
      boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
      transform: "translateY(-2px)",
    },
  },
  eventHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "12px",
  },
  eventTitle: {
    fontSize: "16px",
    fontWeight: "600",
    marginBottom: "4px",
    fontFamily: "'Inter', sans-serif",
  },
  eventDetails: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "8px",
    fontFamily: "'Inter', sans-serif",
  },
  ticketCount: {
    display: "inline-flex",
    alignItems: "center",
    padding: "4px 10px",
    borderRadius: "16px",
    fontSize: "13px",
    fontWeight: "500",
    marginLeft: "8px",
  },
  metaInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "13px",
    color: "#888",
  },
  statusTag: {
    padding: "5px 10px",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: "500",
    display: "inline-block",
    marginRight: "8px",
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    marginTop: "10px",
  },
  unreadIndicator: {
    position: "absolute",
    top: "16px",
    right: "16px",
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  // Special event backgrounds
  qPingStyle: {
    background: "linear-gradient(135deg, #d7aefb 0%, #8e44ad 100%)",
  },
  earlyStyle: {
    background: "linear-gradient(135deg, #9747FF 0%, #D5B3FF 100%)",
  },
  lowStockStyle: {
    background: "linear-gradient(135deg, #b85ec8 0%, #e9ceea 100%)",
  },
  earlyBetaStyle: {
    backgroundColor: "rgb(230, 230, 255)",
  },
}));

const EmailItem = ({
  email = {},
  handleClick,
  event_info = {},
  updateUserData,
  qEvent,
}) => {
  const classes = useStyles();

  let {
    opened,
    eventUrl,
    timestamp = Date.now(),
    quantity = 0,
    priceDrop,
    qPing,
    lowStock,
    stubhub,
  } = email;

  let eventLinkHref = eventUrl;
  if (eventUrl && eventUrl.includes("ticketmaster.com")) {
    eventLinkHref = eventUrl.replace(
      "ticketmaster.com",
      "www.ticketmaster.com"
    );
  } else if (eventUrl && eventUrl.includes("ticketmaster.ca")) {
    eventLinkHref = eventUrl.replace("ticketmaster.ca", "www.ticketmaster.ca");
  }

  let presale = false;
  let onsale = false;

  const { darkMode } = useTheme();

  const {
    name = "Unknown Name",
    venue = "Unknown Venue",
    date = "Unknown Date",
  } = event_info;

  // Get appropriate card style based on email type
  const getCardStyle = () => {
    if (qPing) return classes.qPingStyle;
    if (email.earlyBeta) return classes.earlyBetaStyle;
    if (email.early) return classes.earlyStyle;
    if (lowStock) return classes.lowStockStyle;
    return "";
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(undefined, {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div
      className={`${classes.emailCard} ${
        darkMode ? classes.emailCardDark : classes.emailCardLight
      } ${getCardStyle()}`}
      onClick={() => {
        if (!qPing) {
          handleClick(email);
        } else {
          window.open(eventLinkHref, "_blank");
        }
      }}
    >
      {/* Unread indicator */}
      {!opened && !qPing && priceDrop === undefined && (
        <div
          className={classes.unreadIndicator}
          style={{
            backgroundColor: darkMode
              ? email.early
                ? "#950006"
                : "#ffc001"
              : "blue",
          }}
        />
      )}

      <div className={classes.eventHeader}>
        <div>
          <div
            className={classes.eventTitle}
            style={{
              color: darkMode && !qPing && !lowStock ? "#ffffff" : "#000000",
            }}
          >
            {name}
          </div>
          <div
            className={classes.eventDetails}
            style={{
              color: darkMode && !qPing && !lowStock ? "#cccccc" : "#666666",
            }}
          >
            {venue}, {date}
          </div>
        </div>

        {qPing ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "rgba(255,255,255,0.2)",
              padding: "6px 10px",
              borderRadius: "8px",
            }}
          >
            <img
              src="/Queue-logo.png"
              alt="Q"
              style={{ height: "20px", marginRight: "6px" }}
            />
            <span style={{ fontWeight: "bold", color: "#000" }}>
              QUEUE OPENED
            </span>
          </div>
        ) : (
          <div
            className={classes.ticketCount}
            style={{
              backgroundColor: darkMode
                ? "rgba(255,255,255,0.15)"
                : "rgba(0,0,0,0.08)",
              color: darkMode && !qPing && !lowStock ? "#ffffff" : "#000000",
            }}
          >
            {quantity} tickets
          </div>
        )}
      </div>

      <div className={classes.tagsContainer}>
        {presale && (
          <div
            className={classes.statusTag}
            style={{
              backgroundColor: "#005A9A",
              color: "white",
            }}
          >
            presale
          </div>
        )}

        {onsale && (
          <div
            className={classes.statusTag}
            style={{
              backgroundColor: "#006A17",
              color: "white",
            }}
          >
            onsale
          </div>
        )}

        {priceDrop === true && (
          <div
            className={classes.statusTag}
            style={{
              backgroundColor: "rgba(0, 255, 56, 0.12)",
              color: "#3BE400",
              border: "1px solid #00AC26",
            }}
          >
            price drop
          </div>
        )}

        {priceDrop === false && (
          <div
            className={classes.statusTag}
            style={{
              backgroundColor: "rgba(255, 170, 0, 0.12)",
              color: "#E09500",
              border: "1px solid #AE7400B2",
            }}
          >
            price increase
          </div>
        )}
      </div>

      <div className={classes.metaInfo}>
        <div
          style={{
            color: darkMode && !qPing && !lowStock ? "#888888" : "#666666",
          }}
        >
          Released: {formatDate(timestamp)}
        </div>

        {qEvent && (
          <img
            src="/Queue-logo.png"
            alt="Q-Tickets"
            style={{
              height: "20px",
              filter: darkMode && !email.early ? "invert(1)" : "",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default EmailItem;
