import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";

function SearchBar({
  darkMode,
  searchTerm,
  handleInputChange,
  suggestions,
  performSearch,
  handleSearchClick,
  setInputValue,
}) {
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const inputRef = useRef(null);
  const suggestionsRef = useRef(null);
  const containerRef = useRef(null);

  const uniqueSuggestions = useMemo(
    () => [...new Set(suggestions)],
    [suggestions]
  );

  useEffect(() => {
    setFocusedIndex(-1);
    setShowSuggestions(uniqueSuggestions.length > 0);
  }, [searchTerm, uniqueSuggestions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
        setExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (e) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setFocusedIndex((prevIndex) =>
          prevIndex < uniqueSuggestions.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setFocusedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
        break;
      case "Enter":
        e.preventDefault();
        if (focusedIndex >= 0 && focusedIndex < uniqueSuggestions.length) {
          handleSearchWithValue(uniqueSuggestions[focusedIndex]);
        } else {
          performSearch(searchTerm);
        }
        setShowSuggestions(false);
        break;
      case "Tab":
        e.preventDefault();
        if (uniqueSuggestions.length > 0) {
          const newValue =
            focusedIndex >= 0
              ? uniqueSuggestions[focusedIndex]
              : uniqueSuggestions[0];
          setInputValue(newValue);
          handleInputChange({ target: { value: newValue } });
          setFocusedIndex(0);
        }
        setShowSuggestions(false);
        break;
      case "Escape":
        setShowSuggestions(false);
        setFocusedIndex(-1);
        setExpanded(false);
        break;
      default:
    }
  };

  const handleSearchWithValue = (value) => {
    setInputValue(value);
    performSearch(value);
  };

  const handleSuggestionClick = (suggestion) => {
    handleSearchWithValue(suggestion);
    setShowSuggestions(false);
    setExpanded(false);
  };

  const handleFocus = () => {
    setExpanded(true);
    setShowSuggestions(uniqueSuggestions.length > 0);
  };

  return (
    <>
      <style>
        {`
          .search-floating-container {
            width: 685px;
            position: relative;
            margin: 0 0 16px;
            flex-shrink: 0;
            z-index: 100;
          }
          
          .search-floating-bar {
            display: flex;
            align-items: center;
            background-color: ${darkMode ? "#333" : "#fff"};
            border-radius: ${expanded ? "16px 16px 0 0" : "16px"};
            box-shadow: ${
              expanded
                ? "0 2px 15px rgba(0, 0, 0, 0.2)"
                : "0 2px 10px rgba(0, 0, 0, 0.15)"
            };
            overflow: hidden;
            transition: all 0.3s ease;
            border: 1px solid ${
              expanded
                ? darkMode
                  ? "#555"
                  : "#ddd"
                : darkMode
                ? "#444"
                : "#eee"
            };
          }
          
          .search-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            padding: 15px 5px;
            background-color: ${darkMode ? "#222" : "#f0f0f0"};
            transition: all 0.3s ease;
          }
          
          .search-icon-container svg {
            width: 20px;
            height: 20px;
            transition: all 0.3s ease;
          }
          
          .search-input-field {
            flex: 1;
            border: none;
            background: transparent;
            padding: 15px;
            font-size: 18px;
            color: ${darkMode ? "#fff" : "#333"};
            transition: all 0.3s ease;
            font-family: 'Montserrat', sans-serif;
            width: ${expanded ? "calc(100% - 100px)" : "calc(100% - 50px)"};
          }
          
          .search-input-field::placeholder {
            color: ${darkMode ? "#aaa" : "#999"};
            font-style: italic;
          }
          
          .search-input-field:focus {
            outline: none;
          }
          
          .search-action-button {
            display: ${expanded ? "flex" : "none"};
            align-items: center;
            justify-content: center;
            padding: 12px;
            margin-right: 10px;
            background-color: #FF4081;
            color: white;
            font-weight: bold;
            border-radius: 8px;
            cursor: pointer;
            transition: all 0.2s ease;
            font-family: 'Montserrat', sans-serif;
            font-size: 14px;
          }
          
          .search-action-button:hover {
            background-color: #F50057;
            transform: scale(1.05);
          }
          
          .suggestions-dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: ${darkMode ? "#333" : "#fff"};
            border-radius: 0 0 16px 16px;
            overflow: hidden;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            z-index: 99;
            max-height: 300px;
            overflow-y: auto;
            border: 1px solid ${darkMode ? "#555" : "#ddd"};
            border-top: none;
          }
          
          .suggestion-item-card {
            padding: 15px 20px;
            cursor: pointer;
            color: ${darkMode ? "#fff" : "#333"};
            font-family: 'Montserrat', sans-serif;
            transition: all 0.2s ease;
            border-left: 4px solid transparent;
          }
          
          .suggestion-item-card:hover {
            background-color: ${darkMode ? "#444" : "#f5f5f5"};
            border-left: 4px solid #FF4081;
          }
          
          .suggestion-item-card.active {
            background-color: ${darkMode ? "#444" : "#f5f5f5"};
            border-left: 4px solid #FF4081;
          }
          
          @keyframes slideDown {
            from { opacity: 0; transform: translateY(-10px); }
            to { opacity: 1; transform: translateY(0); }
          }
          
          .suggestions-dropdown {
            animation: slideDown 0.3s ease;
          }
        `}
      </style>

      <div className="search-floating-container" ref={containerRef}>
        <div className="search-floating-bar">
          <div className="search-icon-container">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              stroke={darkMode ? "#ccc" : "#666"}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
          </div>

          <input
            ref={inputRef}
            className="search-input-field"
            type="text"
            placeholder={
              expanded ? "Search for events, artists, venues..." : "Search..."
            }
            value={searchTerm}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
          />

          <div
            className="search-action-button"
            onClick={() => {
              performSearch(searchTerm);
              setShowSuggestions(false);
              setExpanded(false);
            }}
          >
            SEARCH
          </div>
        </div>

        {showSuggestions && expanded && uniqueSuggestions.length > 0 && (
          <div className="suggestions-dropdown" ref={suggestionsRef}>
            {uniqueSuggestions.map((suggestion, index) => (
              <div
                key={suggestion}
                className={`suggestion-item-card ${
                  focusedIndex === index ? "active" : ""
                }`}
                onClick={() => handleSuggestionClick(suggestion)}
                onMouseEnter={() => setFocusedIndex(index)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

SearchBar.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  searchTerm: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  performSearch: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  setInputValue: PropTypes.func.isRequired,
};

export default SearchBar;
