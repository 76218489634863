import React, { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";
import { auth, db } from "../../firebase";

import "../../css/LoginPage.css";
import { LoginForm } from "./forms";
import { handleSession, handleWhopLogin } from "./handler";

const LoginPage = () => {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [error, setError] = useState("");

  useEffect(() => {
    localStorage.setItem("email", email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem("password", password);
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      await handleSession(userCredential, db);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="login-container">
      {error && <div className="error-message">{error}</div>}
      <LoginForm
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        handleSubmit={handleSubmit}
        handleWhopLogin={handleWhopLogin}
      />
    </div>
  );
};

export default LoginPage;
